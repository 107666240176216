import MeshClient from "../client/MeshClient";
import MeshEvents from "../constants/MeshEvents";
import {postMessagePromised} from "../handler/MeshEventHandler";

export default {
    async getAccessToken() {
        const accessTokenRequest = { eventName: MeshEvents.MESH_EVENTS.GET_TOKEN };
        return await postMessagePromised(accessTokenRequest, MeshEvents.MESH_EVENTS.GET_TOKEN);
    },
    printAveryLabel(salOutput, tag) {
        const payload = JSON.stringify({ value : salOutput, tag : tag });
        const printLabelRequest = { eventName: MeshEvents.MESH_EVENTS.PRINT_SAL, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(printLabelRequest));
    },
    setSalFormat(salFormat, tag) {
        const payload = JSON.stringify({ value : salFormat, tag : tag});
        const setSalFormatRequest = { eventName: MeshEvents.MESH_EVENTS.SET_SAL_FORMAT, payload: payload};
        MeshClient.sendMessageToServer(JSON.stringify(setSalFormatRequest));
    },
    averyDisconnect(){
        const closeConnectionAveryRequest = { eventName: MeshEvents.MESH_EVENTS.AVERY_DISCONNECT };
        MeshClient.sendMessageToServer(JSON.stringify(closeConnectionAveryRequest));
    },
    async getAveryConnectionStatus() {
        const averyStatusRequest = { eventName: MeshEvents.MESH_EVENTS.GET_AVERY_CONNECTION_STATUS};
        return await postMessagePromised(averyStatusRequest, MeshEvents.MESH_EVENTS.GET_AVERY_CONNECTION_STATUS);
    },
    async connectAvery(deviceId) {
        const payload = JSON.stringify({ deviceId : deviceId});
        const averyPrinterChangeRequest = { eventName: MeshEvents.MESH_EVENTS.AVERY_CHANGE_PRINTER, payload: payload};
        return await postMessagePromised(averyPrinterChangeRequest, MeshEvents.MESH_EVENTS.AVERY_CHANGE_PRINTER);
    },
    exitModule() {
        const exitModuleRequest = { eventName: MeshEvents.MESH_EVENTS.DESTROY};
        MeshClient.sendMessageToServer(JSON.stringify(exitModuleRequest));
    },
    logoutUser() {
        const logoutUserRequest = { eventName: MeshEvents.MESH_EVENTS.LOGOUT_USER};
        MeshClient.sendMessageToServer(JSON.stringify(logoutUserRequest));
    },
    sblSourceScan(scanContent, scanContentType) {
        const payload = JSON.stringify({ scanContent : scanContent, scanContentType : scanContentType});
        const sblSourceScanRequest = { eventName: MeshEvents.MESH_EVENTS.SBL_SOURCE_SCAN, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(sblSourceScanRequest));
    },
    sblDestinationScan(scanContent, containerId, scanContentType) {
        const payload = JSON.stringify({ scanContent : scanContent, containerId: containerId,
            scanContentType : scanContentType});
        const sblDestinationScanRequest = { eventName: MeshEvents.MESH_EVENTS.SBL_DESTINATION_SCAN, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(sblDestinationScanRequest));
    },
    async connectUsbZebra(){
            const connectUsbZebraRequest = {eventName: MeshEvents.MESH_EVENTS.ZEBRA_CREATE_USB_CONNECTION};
            return await postMessagePromised(connectUsbZebraRequest, MeshEvents.MESH_EVENTS.ZEBRA_CREATE_USB_CONNECTION);
    },
    async connectZebra(macAddress){
        const connectZebraRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_CREATE_CONNECTION, payload: macAddress};
        return await postMessagePromised(connectZebraRequest, MeshEvents.MESH_EVENTS.ZEBRA_CREATE_CONNECTION);
    },
    closeConnectionZebra(){
        const closeConnectionZebraRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_CLOSE_CONNECTION };
        MeshClient.sendMessageToServer(JSON.stringify(closeConnectionZebraRequest));
    },
    async printZebra(printOutput){
        const printZebraRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_PRINT_LABEL, payload: printOutput};
        return await postMessagePromised(printZebraRequest, MeshEvents.MESH_EVENTS.ZEBRA_PRINT_LABEL);
    },
    async getZebraStatus(){
        const zebraStatusRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_STATUS};
        return await postMessagePromised(zebraStatusRequest, MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_STATUS);
    },
    async getZebraResolution(){
        const zebraResolutionRequest = { eventName: MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_RESOLUTION};
        return await postMessagePromised(zebraResolutionRequest, MeshEvents.MESH_EVENTS.ZEBRA_PRINTER_RESOLUTION);
    },
    async updateNotificationStatus(notificationId, notificationStatusToUpdate, priority) {
        const payload = JSON.stringify({ notificationId, notificationStatusToUpdate,
            priority});
        const updateNotificationStatusRequest = { eventName: MeshEvents.MESH_EVENTS.UPDATE_HELP_REQUEST_NOTIFICATION_STATUS, payload: payload };
        MeshClient.sendMessageToServer(JSON.stringify(updateNotificationStatusRequest));
    },
    async getNotificationBellStatus() {
        const getNotificationBellStatusRequest = { eventName: MeshEvents.MESH_EVENTS.GET_HELP_REQUEST_NOTIFICATION_BELL_STATUS };
        MeshClient.sendMessageToServer(JSON.stringify(getNotificationBellStatusRequest));
    },
    async storeHorusVideo(videoMetadata) {
        const payload = JSON.stringify(videoMetadata);
        const storeHorusVideoRequest = {eventName: MeshEvents.MESH_EVENTS.STORE_HORUS_VIDEO, payload: payload}
        MeshClient.sendMessageToServer(JSON.stringify(storeHorusVideoRequest));
    }
};